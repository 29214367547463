<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>VPS Minimal</h1>
        <Price :item="data.staticData.prices.SSLCertificatesPrices[0]"/>
        <h2>VPS Minimal</h2>
        <p>VPS hosting met ons goedkoopste pakket biedt alle mogelijkheden van een virtual private server, zonder dat u daarvoor een hoge prijs betaalt. Maak gebruik van een voordelig pakket, waarmee u de volledige virtuele vrijheid heeft. Het gemak van een eigen server met het besturingssysteem van uw keuze, zonder dat u daarvoor zelf de hardware hoeft aan te schaffen.</p>
        <p>Met ons goedkoopste pakket voor een virtual private server kiest u voor 1GB RAM en 10GB HDD aan opslagruimte. U heeft de beschikking over 25GB aan internetverkeer en dankzij de super snelle CPU core kunt u rekenen op een vlotte laadtijd van uw website(s) of applicatie(s).</p>
        <h3>Eigen virtuele server</h3>
        <p>Met VPS hosting op basis van ons goedkoopste pakket heeft u de beschikking over een eigen virtuele server. Dat betekent een virtueel afgebakende ruimte op een fysieke server, waardoor u zelf geen hardware hoeft aan te schaffen. U kunt daar gebruik van maken op basis van Linux VPS, net als met een ander besturingssysteem. U heeft de volledige vrijheid van een virtuele server, zonder dat er een specifieke fysieke server aan te pas hoeft te komen.</p>
        <ul>
          <li>Vrijheid van een virtuele server</li>
          <li>10GB HDD opslagruimte</li>
          <li>25GB dataverkeer voor al uw websites of applicaties</li>
          <li>Kies zelf het besturingssysteem</li>
        </ul>
        <h3>Kies zelf het besturingssysteem (OS)</h3>
        <p>Op een virtual private server op basis van ons goedkoopste pakket voor VPS hosting bepaalt u zelf het besturingssysteem dat u wilt gebruiken. Een voorkeur voor Linux VPS? Geen probleem, u kunt zelf het gewenste systeem installeren op de virtuele server. Volledige vrijheid, zonder dat u daar aan de andere kant een eigen fysieke server voor hoeft aan te schaffen. Niet de investering in de apparatuur, maar wel maximaal betrouwbare hosting op basis van een eigen software-omgeving.</p>
        <p><i>“We gebruiken Linux VPS, een prettig besturingssysteem dat goed aansluit bij onze werkwijze. Fijn dat we die vrijheid hebben door middel van VPS hosting bij Hosting on Demand”</i>, aldus een tevreden ondernemer.</p>
        <h3>VPS hosting met 1 CPU core</h3>
        <p>Met het goedkoopste pakket voor VPS hosting kiest u voor een omgeving op basis van 1 CPU core, voldoende om uw website(s) of applicatie(s) vlot te laten laden. Bekijk daarnaast de pakketten met 2, 3 of 4 cores voor maximale prestaties binnen de VPS-omgeving. Direct online te bestellen.</p>
      </div>
      <CloudServersRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Price from '@/components/Price.vue';
import CloudServersRightSideBlocks from '@/components/CloudServersRightSideBlocks.vue';

export default {
  name: 'VPSMinimal',
  components: {
    Breadcrumbs,
    Price,
    CloudServersRightSideBlocks,
  },
  setup() {
    const data = inject('data');

    return {
      // store
      data,
    };
  },
}
</script>